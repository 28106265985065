declare global { interface Window {avvLoading: boolean}}

export class LoadingHelpers {
  static startLoading (selector = '.loading-screen', position?: string, text?: string) {
    const loadingElem = document.querySelector(selector)
    if(loadingElem && loadingElem instanceof HTMLElement){
      loadingElem.style.display = 'flex'
      loadingElem.style.opacity = '100'
      if(position) loadingElem.style.position = position
      if(text) {
        const textElem = loadingElem.querySelector('.loading-screen_text')
        if(textElem) textElem.innerHTML = text
      }

    }

    window.avvLoading = true
  }

  static stopLoading(selector: string = '.loading-screen') {
    const loadingElem = document.querySelector(selector)
    if(loadingElem && loadingElem instanceof HTMLElement){
      loadingElem.style.opacity = '0'
      loadingElem.style.pointerEvents = 'none'
      setTimeout(() => {
        loadingElem.style.display = 'none'
      }, 500)
    }

    window.avvLoading = false
  }
}
